<template>
  <div>
    <h4 class="page-title">购买设置</h4>
    <a-row class="rows">
      <a-col class="left required" span="3">所属商品类别</a-col>
      <a-col class="right" span="20">
        <a-tree-select v-model="formData.productCategoryIdList" style="width:240px;"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="productCategoryList" tree-checkable
          placeholder="请选择所属商品类别" :replaceFields="{
            children: 'children', title: 'name', key: 'id', value: 'id'
          }" :treeDefaultExpandAll="true" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left required" span="4">商品价格设置</a-col>
      <a-col class="right" span="20">
        <div>
          <span>市场价(原价)：</span>
          <a-input-number :precision="2" :min="0" v-model="formData.originalPrice"
            style="width:120px;margin-left:8px;" />
          <span style="margin-left:8px;">元，</span>
          <a-checkbox @change="changeSetOriginal" :checked="formData.setOriginal == '1'">展示原价</a-checkbox>
        </div>
        <div style="margin-top:10px;">
          <span>优惠价：</span>
          <a-input-number :precision="2" :min="0" :max="formData.originalPrice" v-model="formData.couponPrice"
            :disabled="courseFree == 1" style="width:120px;margin-left:8px;" />
          <span style="margin-left:8px;">元</span>
          <!-- <a-checkbox>是否在终端展示</a-checkbox> -->
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">关联商品设置</a-col>
      <a-col class="right" span="19">
        <div>
          <span>关联商品：</span>
          <a-radio-group class="radioGroup" v-model="formData.setRelevantProduct">
            <a-radio value="0" style="line-height:32px;">无关联</a-radio>
            <a-radio value="1" style="line-height:32px;">
              <span>有关联</span>
              <a v-if="formData.setRelevantProduct == '1'" style="margin-left:14px;"
                @click="$refs.ModalProduct.open({ type: 'setRelevantProduct', selectedRowKey: funkeyId(setRelevantProductList), data: setRelevantProductList })">添加商品+</a>
            </a-radio>
          </a-radio-group>
        </div>
        <div style="margin-top:10px;" v-if="formData.setRelevantProduct == '1'">
          <a-table class="table-template" @change="onProductPage"
            :rowKey="item => 'setRelevantProductList' + item.productId" :columns="columns4"
            :data-source="setRelevantProductList" :pagination="{
              total: total,
              current: productNumber,
              pageSizeOptions: ['5', '10', '20', '50'],
              defaultPageSize: productPageSize,
              showSizeChanger: true,
              showTotal: function (total, range) {
                return `共${total}条`
              }
            }">
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ (productNumber - 1) * productPageSize + i + 1 }}
              </div>
            </template>

            <template slot="operation" slot-scope="item, row">
              <div class="btn_router_link">
                <a @click="removeRelevantProduct(row.productId)">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">赠品设置</a-col>
      <a-col class="right" span="19">
        <div>
          <span>关联赠品：</span>
          <a-radio-group class="radioGroup" v-model="formData.setRelevantGift">
            <a-radio value="0" style="line-height:32px;">无赠品</a-radio>
            <a-radio value="1" style="line-height:32px;">
              <span>有赠品</span>
              <a v-if="formData.setRelevantGift == '1'" style="margin-left:14px;"
                @click="$refs.ModalProduct.open({ type: 'setRelevantGift', selectedRowKey: funkeyId(setRelevantGiftList), data: setRelevantGiftList })">添加赠品+</a>
            </a-radio>
          </a-radio-group>
        </div>
        <div style="margin-top:10px;" v-if="formData.setRelevantGift == '1'">
          <a-table class="table-template" @change="onGiftPage" :rowKey="item => 'setRelevantGiftList' + item.productId"
            :columns="columns4" :data-source="setRelevantGiftList" :pagination="{
              total: total,
              current: giftPageNumber,
              pageSizeOptions: ['5', '10', '20', '50'],
              defaultPageSize: giftPageSize,
              showSizeChanger: true,
              showTotal: function (total, range) {
                return `共${total}条`
              }
            }">
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ (giftPageNumber - 1) * giftPageSize + i + 1 }}
              </div>
            </template>

            <template slot="operation" slot-scope="item, row">
              <div class="btn_router_link">
                <a @click="removesetRelevantGift(row.productId)">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">购买限制条件</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.setBuyLimit">
          <a-radio value="0">无购买限制</a-radio>
          <a-radio value="1">有购买限制</a-radio>
        </a-radio-group>
        <template v-if="formData.setBuyLimit == '1'">
          <div style="margin-top:10px;">
            <a-checkbox v-model="setBuyLimitType1" style="display:block;">在平台购买以下课程方可购买</a-checkbox>
            <div style="margin-top:10px;line-height:32px;" v-if="setBuyLimitType1">
              <span>选择课程</span>
              <a style="margin-left:8px;" @click="$refs.Modal.open({
                title: '选择课程',
                width: '1000px',
                type: 'SelectCourse'
              })">添加 +</a>
              <div class="fontSize">
                <div v-for="item in SelectCourseList" :key="'SelectCourseList' + item.courseId">
                  <span>{{ item.courseName }}</span>
                  <a style="margin-left:8px;" @click="removeSelectCourseList(item)">删除</a>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:24px;">
            <a-checkbox v-model="setBuyLimitType2" style="display:block;">人工审核相关资质通过后可购买</a-checkbox>
            <div style="line-height:32px;margin-top:10px;" v-if="setBuyLimitType2">
              <span>提交证明提示语</span>
              <a-textarea v-model="formData.buyPromptContent"
                style="width:360px;margin-left:8px;vertical-align: top;" />
            </div>
          </div>
          <!-- <div style="margin-top:10px;">
            <a-checkbox v-model="setBuyLimitType3" style="display:block;">用户需要完成身份认证才可购买</a-checkbox>
          </div> -->
        </template>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">购买权限设置</a-col>
      <a-col class="right" span="20">
        <span>用户需要完成身份认证后才可购买：</span>
        <a-radio-group class="radioGroup" v-model="formData.userAuthentication">
          <a-radio value="1">需要</a-radio>
          <a-radio value="0">不需要</a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <!-- <a-row class="rows">
      <a-col class="left" span="3">商品上架设置</a-col>
      <a-col class="right" span="20">
        <a-radio-group class="radioGroup" v-model="formData.groundSet">
          <a-radio value="1">暂不上架</a-radio>
          <a-radio value="2">提交即上架</a-radio>
          <a-radio value="3" style="line-height: 32px">
            <span>设置上架时间</span>
            <a-date-picker
              show-time
              :disabled-date="disabledDate"
              :disabledTime="disabledDateTime"
              :disabled="formData.groundSet != '3'"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              v-model="formData.groundTime"
              style="margin-left: 10px"
              placeholder="设置上架时间"
            />
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row> -->
    <a-row class="rows">
      <a-col class="left" span="3">成功报名课程文案</a-col>
      <a-col class="right" span="20">
        <a-select style="width: 300px" :open="false" @click.native="selectSmsCodevisible = true"
          v-model="formData.smsCode" placeholder="请选择报名审核通过文案">
          <a-select-option :value="item.smsCode" v-for="(item, index) in smsCordList" :key="index">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">商品终端是否展示</a-col>
      <a-col class="right" span="20">
        <a-checkbox class="radioGroup" @change="changeDisplayFlag" :checked="formData.displayFlag == '1'"></a-checkbox>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">是否可重复购买</a-col>
      <a-col class="right" span="20">
        <a-checkbox class="radioGroup" @change="changeBuyLimitNum" :checked="formData.buyLimitNum == '1'"></a-checkbox>
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col class="left" span="3">购买须知</a-col>
      <a-col class="right" span="20">
        <UE editorId="introduceEditor1" :initContent="formData.prebuyNotice" @input="getContent" />
      </a-col>
    </a-row>
    <a-row class="rows">
      <a-col span="20" offset="3">
        <a-button v-if="$route.query.status == 1" @click="onStorageClick" :loading="loadingBtn" type="primary"
          style="margin-right: 30px">暂存</a-button>
        <a-button :loading="loadingBtn" @click="onSaveClick">保存</a-button>
        <a-button :disabled="!saveType" style="margin-left: 30px;" @click="showAddRelation(1)">增加销售关系</a-button>
      </a-col>
    </a-row>

    <a-row class="rows">
      <a-col class="left required" span="3">销售关系设置</a-col>
      <a-col class="right" span="20" >
        <template v-if="listData && listData.length">
          <a-table class="table-template" :loading="loading" :columns="columns"
            :data-source="listData" :defaultExpandAllRows="true" :pagination="false"
            :rowKey="item => item.relationId">
            <!-- 序号 -->
            <template slot="dataIndex" slot-scope="item, row, index">
              <span>{{ index + 1 }}</span>
            </template>
            <!-- 销售关系 -->
            <template slot="relationType" slot-scope="item">
              <span>{{ handleRelationType(item) }}</span>
            </template>
            <!-- 显示名称 -->
            <template slot="titleName" slot-scope="item">
              <span>{{ item ? item : '-' }}</span>
            </template>
            <!-- 购买价格 -->
            <template slot="price" slot-scope="item">
              <span>{{ item ? item : '-' }}</span>
            </template>
            <!-- 图片 -->
            <template slot="photo" slot-scope="item">
              <viewer v-if="item">
                <img class="pictrue" :src="item" alt="" />
              </viewer>
              <span v-else>-</span>
            </template>
            <template slot="sort" slot-scope="item,row">
              <a-input-number id="inputNumber" v-model="row.sort" :min="0" @change="onSortChange(row)" />
            </template>
            <!-- 关系操作 -->
            <template slot="relationId" slot-scope="item">
              <template>
                <a @click="onGetRelationDetail(item)">编辑</a>
                <span> | </span>
                <a-popconfirm title="是否确认删除销售关系?" ok-text="是" cancel-text="否" @confirm="removeRelation(item)">
                  <a href="#">删除</a>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
        </template>
        <div v-else>
          <a-empty description="暂无销售关系" />
        </div>
      </a-col>
    </a-row>
    <!-- 弹出窗 -->
    <Modal ref="Modal" @ok="onModal" />
    <!-- 弹窗 - 选择商品 -->
    <ModalProduct ref="ModalProduct" @ok="onModalProduct" />

    <!-- 选择短信文案 -->
    <a-modal title="成功报名课程文案" width="800px" okText="确认" :visible="selectSmsCodevisible" @ok="selectSmsCode"
      @cancel="selectSmsCodevisible = false">
      <a-table class="table-template" :rowKey="(item) => item.smsCode" :columns="smsCodecolumns"
        :data-source="smsCordList" @change="onSmsCodePage" :row-selection="{
          selectedRowKeys: selectedSmsCodeRowKeys,
          onChange: selectSmsCodeItem,
          type: 'radio',
          columnTitle: '选择',
        }" :pagination="{
          total: smsCodeObj.total,
          current: smsCodeObj.pageNumber,
          defaultPageSize: smsCodeObj.pageSize,
          showSizeChanger: false,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (smsCodeObj.pageNumber - 1) * 10 + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>

    <!-- 新建/编辑 商品销售关系弹窗 -->
    <a-modal v-model="relationVisible" title="添加销售关系" :confirm-loading="confirmLoading" @ok="onAddRelation" okText="确认"
      @cancel="handleCancel()">
      <a-row class="rows">
        <a-col span="5" class="left required">商品名称</a-col>
        <a-col span="19" class="right flex">
          <a-auto-complete style="width: 100%" v-model="relationAddForm.relatedProductName" placeholder="输入搜索关键字"
            option-label-prop="value" @search="onSearchRole" :defaultActiveFirstOption="false">
            <a-spin v-if="fetching" class="auto-spin" slot="notFoundContent" size="small" />
            <template slot="dataSource">
              <a-select-option @click="onSelectRole(item)" v-for="(item, index) in list" :key="index + ''"
                :value="item.productName">
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col>{{ item.productName }}</a-col>
                </a-row>
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left required">销售关系</a-col>
        <a-col span="19" class="right flex">
          <a-select placeholder="请选择销售关系"  style="width: 100%" v-model="relationAddForm.relationType" @change="resetData">
            <a-icon slot="suffixIcon" type="caret-down" />
            <!-- 1-赠品 2-相关商品 3-组合商品 4-规格 5-推荐商品） -->
            <a-select-option :value="1">赠品</a-select-option>
            <a-select-option :value="2">相关商品</a-select-option>
            <a-select-option :value="3">组合商品</a-select-option>
            <a-select-option :value="4">规格</a-select-option>
            <a-select-option :value="5">系列商品</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">商品类别</a-col>
        <a-col span="19" class="right flex">
          <a-radio-group v-model="relationAddForm.productCategory">
            <a-radio :value="0">个人商品</a-radio>
            <a-radio :value="1">机构商品</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">显示名称</a-col>
        <a-col span="19" class="right flex">
          <a-input v-model="relationAddForm.title" placeholder="请填写显示名称" />
        </a-col>
      </a-row>
      <template v-if="relationAddForm.relationType == 3">
      <a-row class="rows">
        <a-col span="5" class="left">购买价格</a-col>
        <a-col span="19" class="right flex">
          <a-input v-model="relationAddForm.price" placeholder="请输入购买价格" />
        </a-col>
      </a-row>
      </template>
      
      <!-- <a-row class="rows">
        <a-col span="5" class="left">排序</a-col>
        <a-col span="19" class="right flex">
          <a-input v-model="relationAddForm.sort" placeholder="请填写排序权重" />
        </a-col>
      </a-row> -->
      <a-row class="rows">
        <a-col span="5" class="left">显示图片</a-col>
        <a-col span="19" class="right">
          <a-upload name="picture" accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" list-type="picture-card"
            :showUploadList="false" :loading="beforeUpload" :customRequest="customRequestProduct">
            <img v-if="relationAddForm.photo" :src="relationAddForm.photo" style="max-width: 180px" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { uuid } from "@/unit/fun.js";
import config from '../CourseDetails/config.vue'
import { number } from "echarts";

const columns = [
  {
    title: "序号",
    dataIndex: "dataIndex",
    scopedSlots: {
      customRender: "dataIndex",
    },
  },
  {
    title: "关联商品名称",
    align: "center",
    dataIndex: "relatedProductName",
    scopedSlots: { customRender: "relatedProductName" },
  },
  {
    title: "销售关系",
    align: "center",
    dataIndex: "relationType",
    scopedSlots: { customRender: "relationType" },
  },
  {
    title: "显示名称",
    align: "center",
    dataIndex: "title",
    scopedSlots: { customRender: "titleName" },
  },
  {
    title: "购买价格",
    align: "center",
    dataIndex: "price",
    scopedSlots: { customRender: "price" },
  },
  {
    title: "显示图片",
    align: "center",
    dataIndex: "photo",
    width: "100px",
    scopedSlots: { customRender: "photo" },
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: { customRender: "sort" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "relationId",
    scopedSlots: { customRender: "relationId" },
  },
];
const columns4 = [
  {
    title: "序号",
    width: "80px",
    align: 'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "商品名称",
    align: 'center',
    dataIndex: "productName"
  },
  {
    title: "操作",
    align: 'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];
// 短信文案弹框表格
const smsCodecolumns = [
  {
    title: "场景",
    width: "200px",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "文案",
    align: "center",
    dataIndex: "content",
  },
];
export default {
  mixins: [config],
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns4,
      loadingBtn: false,
      productCategoryList: [], // 商品分类
      setRelevantProductList: [], // 关联商品集合
      setRelevantGiftList: [], // 关联赠品集合
      SelectCourseList: [], // 选择的课程
      // 关联商品分页
      productNumber: 1,
      productPageSize: 5, //条数
      // 赠品分页
      giftPageNumber: 1,
      giftPageSize: 5, //条数
      total: 0,

      setBuyLimitType1: false, // 在平台购买以下课程方可购买
      setBuyLimitType2: false, // 在平台购买以下课程方可购买
      // setBuyLimitType3: false, // 用户需要完成身份认证才可购买

      formData: {
        courseId: 0, // 课程id
        productCategoryIdList: [], // 所属商品类别
        originalPrice: 0, // 市场价（原价）
        setOriginal: '0', // 市场价（原价）是否在终端展示
        displayFlag: '1', // 是否在终端展示商品(0.否,1是)
        buyLimitNum: '0', // 是否可重复购买
        couponPrice: '0', // 优惠价
        setRelevantProduct: '0', // 是否关联商品1.是 0.否
        setRelevantGift: '0', // 是否关联赠品1.是 0.否
        setBuyLimit: '0', // 是否有购买限制1.有 0.没有
        buyLimitType: '', // 购买条件类型1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
        buyPromptContent: '', // 提交证明提示语
        userAuthentication: '0', // 用户需要完成身份认证后才可购买1.是 0.否
        smsCode: undefined, // 报名审核通过文案code码
        saveType: 1, // 保存类型(0-未填写，1-暂存，2-提交)
        prebuyNotice: '', // 购买须知
      },

      saveType: false, // 提交状态  ture: 提交过 false:未提交过
      productType: false, // 商品状态


      // 短信文案配置
      smsCodecolumns,
      selectedSmsCodeRowKeys: [], // 短信文案选择
      selectSmsCodevisible: false, // 选择报名审核通过文案
      smsCordList: [], // 考试设置短信文案列表
      smsCodeObj: {
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 5, // 页数
      },

      // 商品销售关系配置
      relationVisible: false, // 是否显示新建/编辑销售关系弹窗
      fetching: false,
      list: [],
      productId: 0,
      confirmLoading: false,
      submitLoading: false, // 新增销售关系loading
      listData: [], // 销售关系列表
      columns, // 销售关系列表配置
      relationEditType: 0, // 1：新增 2：编辑
      // 商品参数
      productForm: {
        name: '',
        page: 1,
        size: 999,
        noExam: 0 // 是否查询模拟考试商品 1查 0 不查
      },
      // 新增销售关系参数
      relationAddForm: {
        photo: null,
        price: null,
        productCategory: 0, // 商品类别（0：个人商品 1：机构商品）	
        productId: null,
        relatedProductId: null, // 关联商品ID
        relationType: undefined, // 关系类型（1-赠品 2-相关商品 3-组合商品 4-规格 5-推荐商品）
        sort: 0,
        title: null // 显示名称
      },
    };
  },
  // 事件处理器
  methods: {
    // 购买须知富文本
    getContent(e) {
      this.formData.prebuyNotice = e
    },

    // 切换关联商品
    onProductPage(e) {
      this.productNumber = e.current
      this.productPageSize = e.pageSize
    },
    // 切换赠品分页
    onGiftPage(e) {
      this.giftPageNumber = e.current
      this.giftPageSize = e.pageSize
    },

    // 暂存检查Form表单并返回object
    fun_StorageVerific() {
      const data = this.formData
      let obj = new Object()
      let arr = []

      // 课程id
      obj.courseId = data.courseId

      // 商品id
      obj.productId = data.productId

      // 所属商品类别
      obj.productCategoryIdList = data.productCategoryIdList

      // 商品价格设置 - 市场价(原价)
      obj.originalPrice = data.originalPrice

      // 商品价格设置 - 市场价是否在终端展示
      obj.setOriginal = Number(data.setOriginal) ? '1' : '0'

      // 商品设置 - 商品是否在终端展示
      obj.displayFlag = Number(data.displayFlag) ? '1' : '0'

      // 商品设置 - 是否可重复购买
      obj.buyLimitNum = Number(data.buyLimitNum) ? '1' : '0'

      // 商品价格设置 - 优惠价
      obj.couponPrice = data.couponPrice

      // 关联商品设置
      obj.setRelevantProduct = data.setRelevantProduct

      // 关联商品
      obj.relevantProduct = []
      if (data.setRelevantProduct == '1') {
        arr = []
        this.setRelevantProductList.forEach(item => {
          arr.push({
            relevantProductId: item.productId,
            type: 1
          })
        })
        obj.relevantProduct = arr
      }

      // 赠品设置
      obj.setRelevantGift = data.setRelevantGift

      // 关联赠品
      if (data.setRelevantGift == '1') {
        arr = []
        this.setRelevantGiftList.forEach(item => {
          arr.push({
            relevantProductId: item.productId,
            type: 2
          })
        })
        obj.relevantProduct = [...obj.relevantProduct, ...arr]
      }

      // 是否有购买限制条件
      obj.setBuyLimit = data.setBuyLimit

      // 购买限制条件 1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
      if (this.setBuyLimitType1 && this.setBuyLimitType2) {
        obj.buyLimitType = 3
      } else if (this.setBuyLimitType1) {
        obj.buyLimitType = 1
      } else if (this.setBuyLimitType2) {
        obj.buyLimitType = 2
      } else {
        obj.buyLimitType = 0
      }

      // 在平台购买以下课程方可购买
      arr = []
      this.SelectCourseList.forEach(item => {
        arr.push(item.courseId)
      })
      obj.buyLimitCourseList = arr

      // 提交证明提示语
      obj.buyPromptContent = data.buyPromptContent

      // 用户需要完成身份认证后才可购买
      obj.userAuthentication = data.userAuthentication

      // 报名审核通过文案code码
      obj.smsCode = data.smsCode

      // 购买须知
      obj.prebuyNotice = data.prebuyNotice

      return Promise.resolve(obj)
    },

    // 提交检查Form表单并返回object
    fun_SaveVerific() {
      const data = this.formData
      let obj = new Object()
      let arr = []

      // 课程id
      obj.courseId = data.courseId

      // 商品id
      obj.productId = data.productId

      // 所属商品类别
      if (!data.productCategoryIdList || data.productCategoryIdList.length == 0) {
        return Promise.reject({ msg: '请选择所属商品类别' })
      } else {
        obj.productCategoryIdList = data.productCategoryIdList
      }

      // 商品价格设置 - 市场价(原价)
      // if(data.originalPrice== '' || data.originalPrice == 0){
      //   return Promise.reject({msg:'请输入商品市场价格'})
      // }else{
      //   obj.originalPrice = data.originalPrice
      // }
      obj.originalPrice = data.originalPrice


      // 商品价格设置 - 市场价是否在终端展示
      obj.setOriginal = Number(data.setOriginal) ? '1' : '0'

      // 商品设置 -商品是否在终端展示
      obj.displayFlag = Number(data.displayFlag) ? '1' : '0'

      // 商品设置 -是否可重复购买
      obj.buyLimitNum = Number(data.buyLimitNum) ? '1' : '0'

      // 商品价格设置 - 优惠价
      // if(data.couponPrice == '' || data.couponPrice == 0){
      //   return Promise.reject({msg:'请输入商品优惠价格'})
      // }else{
      //   obj.couponPrice = data.couponPrice
      // }
      obj.couponPrice = data.couponPrice

      // 关联商品设置
      obj.setRelevantProduct = data.setRelevantProduct

      // 关联商品
      obj.relevantProduct = []
      if (data.setRelevantProduct == '1') {
        if (!this.setRelevantProductList || this.setRelevantProductList.length == 0) {
          return Promise.reject({ msg: '请添加关联商品' })
        }
        arr = []
        this.setRelevantProductList.forEach(item => {
          arr.push({
            relevantProductId: item.productId,
            type: 1
          })
        })
        obj.relevantProduct = arr
      }

      // 赠品设置
      obj.setRelevantGift = data.setRelevantGift

      // 关联赠品
      if (data.setRelevantGift == '1') {
        if (!this.setRelevantGiftList || this.setRelevantGiftList.length == 0) {
          return Promise.reject({ msg: '请添加关联赠品' })
        }
        arr = []
        this.setRelevantGiftList.forEach(item => {
          arr.push({
            relevantProductId: item.productId,
            type: 2
          })
        })
        obj.relevantProduct = [...obj.relevantProduct, ...arr]
      }

      // 是否有购买限制条件
      obj.setBuyLimit = data.setBuyLimit

      // 购买限制条件 1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
      if (this.setBuyLimitType1 && this.setBuyLimitType2) {
        obj.buyLimitType = 3
      } else if (this.setBuyLimitType1) {
        obj.buyLimitType = 1
      } else if (this.setBuyLimitType2) {
        obj.buyLimitType = 2
      } else {
        obj.buyLimitType = 0
      }

      // 在平台购买以下课程方可购买
      arr = []
      this.SelectCourseList.forEach(item => {
        arr.push(item.courseId)
      })
      obj.buyLimitCourseList = arr

      // 用户需要完成身份认证后才可购买
      obj.userAuthentication = data.userAuthentication

      // 提交证明提示语
      obj.buyPromptContent = data.buyPromptContent

      // 报名审核通过文案code码
      obj.smsCode = data.smsCode

      // 购买须知
      obj.prebuyNotice = data.prebuyNotice

      return Promise.resolve(obj)
    },

    // 组装返现表单数据
    fun_detail(data) {
      let obj = {};
      let arr = [];

      // 课程id
      obj.courseId = data.courseId

      // 商品id
      obj.productId = data.productId

      // 所属商品类别
      obj.productCategoryIdList = data.productCategoryIdList;

      // 商品价格设置 - 市场价(原价)
      obj.originalPrice = data.originalPrice;

      // 商品价格设置 - 市场价是否在终端展示
      obj.setOriginal = data.setOriginal ? "1" : "0";

      // 商品设置 - 商品是否在终端展示
      obj.displayFlag = data.displayFlag ? "1" : "0";

      // 商品设置 - 是否可重复购买
      obj.buyLimitNum = data.buyLimitNum ? "1" : "0";

      // 商品价格设置 - 优惠价
      obj.couponPrice = data.couponPrice;

      // 关联商品设置
      obj.setRelevantProduct = String(data.setRelevantProduct);

      // 关联商品
      if (data.setRelevantProduct == "1") {
        arr = [];
        data.relevantProductVO.forEach((item) => {
          if (item.type == 1) {
            item.uid = uuid();
            arr.push(item);
          }
        });
        this.setRelevantProductList = arr;
      }

      // 赠品设置
      obj.setRelevantGift = String(data.setRelevantGift);

      // 关联赠品
      if (data.setRelevantGift == "1") {
        arr = [];
        data.relevantProductVO.forEach((item) => {
          if (item.type == 2) {
            item.uid = uuid();
            arr.push(item);
          }
        });
        this.setRelevantGiftList = arr;
      }

      // 是否有购买限制条件
      obj.setBuyLimit = String(data.setBuyLimit);

      // 购买限制条件 1.在平台购买以下课程方可购买 2.人工审核相关资质通过后可购买 3.二者皆可
      if (data.buyLimitType == 3) {
        this.setBuyLimitType1 = true;
        this.setBuyLimitType2 = true;
      } else if (data.buyLimitType == 1) {
        this.setBuyLimitType1 = true;
      } else if (data.buyLimitType == 2) {
        this.setBuyLimitType2 = true;
      }

      // 限制课程
      if (!!data.buyLimitCourseVOList && data.buyLimitCourseVOList.length > 0) {
        arr = [];
        data.buyLimitCourseVOList.forEach((item) => {
          item.uid = uuid();
          item.courseId = item.haveCourseId;
          arr.push(item);
        });
        this.SelectCourseList = arr;
      }

      // 提交证明提示语
      obj.buyPromptContent = data.buyPromptContent;

      // 用户需要完成身份认证后才可购买
      obj.userAuthentication = String(data.userAuthentication);

      // 报名审核通过文案code码
      obj.smsCode = data.smsCode

      this.selectedSmsCodeRowKeys = [data.smsCode];

      // 购买须知
      obj.prebuyNotice = data.prebuyNotice

      return obj
    },

    // 暂存
    onStorageClick() {
      // console.log(this.formData);
      this.loadingBtn = true
      Promise.all([
        this.fun_StorageVerific()
      ]).then(res => {
        let obj = res[0]
        obj.saveType = 1
        this.onSubmitClick(obj)
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    // 提交
    onSaveClick() {
      if (!this.formData.originalPrice) {
        if (this.formData.originalPrice !== 0) {
          return this.$message.warning('请填写原价！')
        }
      }
      if (!this.formData.couponPrice) {
        if (this.formData.couponPrice !== 0) {
          return this.$message.warning('请填写优惠价！')
        }
      }
      this.loadingBtn = true
      Promise.all([
        this.fun_SaveVerific()
      ]).then(res => {
        let obj = res[0]
        obj.saveType = 2
        this.onSubmitClick(obj)
      }).catch(err => {
        if (err) {
          this.$message.warning(err.msg)
        } else {
          console.error(err)
        }
        this.loadingBtn = false
      })
    },

    onSubmitClick(obj) {
      this.loadingBtn = true
      let url = ''
      if (this.saveType && this.productType) {
        url = "/hxclass-management/course/updateCourseBuyConfig"
      } else {
        url = "/hxclass-management/course/saveCourseBuyConfig"
      }
      this.$message.loading({ content: "课程信息正在存储...", duration: 0 });
      this.$ajax({
        url: url,
        method: "POST",
        params: obj,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success((obj.saveType == 1 ? '暂存' : '保存') + '成功!')
          this.loadingBtn = false
          if (!this.saveType && !this.productType) {
            this.getFormDetail()
          }
          this.saveType = true
          // 向父页面更新目录修改状态
          this.$parent.onChildInit('buyConfig', obj.saveType);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询详情
    getFormDetail() {
      this.loadingBtn = true
      this.$message.loading({ content: "正在查询详情...", duration: 0 });
      this.$ajax({
        url: "/hxclass-management/course/getCourseBuyConfig",
        params: {
          courseId: this.formData.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.saveType = res.data.saveType ? true : false
          this.productType = res.data.productId ? true : false
          this.productId = res.data.productId
          if (this.saveType) {
            this.formData = this.fun_detail(res.data);
          }

          this.$message.success("查询成功!");
          this.onGetRelationList();
        } else {
          this.$message.error(res.message);
        }
        this.loadingBtn = false
      });
    },

    // 查询考试设置短信文案列表
    getSmsCordList() {
      this.$ajax({
        url: '/hxclass-management/default/short/message/manageGetSmsList',
        method: 'get',
        params: {
          sceme: '成功报名购买课程'
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.smsCodeObj.total = res.data.length;
          this.smsCordList = res.data;
        }
      })
    },

    // 选择短信文案
    selectSmsCode() {
      this.formData.smsCode = this.selectedSmsCodeRowKeys[0];
      this.selectSmsCodevisible = false;
    },

    // 短信文案选择
    selectSmsCodeItem(selectedRowKeys) {
      let arr = selectedRowKeys.pop();
      this.selectedSmsCodeRowKeys = [arr];
    },
    // 短信文案分页
    onSmsCodePage(e) {
      this.smsCodeObj.pageNumber = e.current;
      this.smsCodeObj.pageSize = e.pageSize;
    },
    // 展示新增销售关系弹窗
    showAddRelation(type) {
      this.relationEditType = type;
      this.relationVisible = true;
    },
    // 增加/修改销售关系
    onAddRelation() {
      if (!this.relationAddForm.relatedProductId) {
        this.$message.warning("请选择商品");
        return;
      }
      if (!this.relationAddForm.relationType) {
        this.$message.warning("请选择销售关系类型");
        return;
      }
      this.relationAddForm.productId = this.productId;
      this.relationAddForm.price = this.relationAddForm.price ? Number(this.relationAddForm.price):null;
      // console.log("========" + JSON.stringify(this.relationAddForm));
      // return;
      this.submitLoading = true;
      this.$ajax({
        url: '/hxclass-management/productRelation',
        method: this.relationEditType == 1 ? 'post' : 'put',
        params: this.relationAddForm,
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.$message.success(this.relationEditType == 1 ? "新增销售关系成功":"修改销售关系成功");
          this.relationAddForm.relatedProductId = null;
          this.relationAddForm.photo = null;
          this.relationAddForm.price = null;
          this.relationAddForm.productCategory = 0;
          this.relationAddForm.productId = null;
          this.relationAddForm.relationType = undefined;
          this.relationAddForm.sort = 0;
          this.relationAddForm.title = null;
          this.relationVisible = false
          this.onGetRelationList()
        } else {
          this.$message.error(res.message)
        }
        this.submitLoading = false
      })

    },
    // 弹窗关闭重置信息
    handleCancel() {
      this.relationAddForm.relatedProductId = null;
      this.relationAddForm.relatedProductName = null;
      this.relationAddForm.photo = null;
      this.relationAddForm.price = 0;
      this.relationAddForm.productCategory = 0;
      this.relationAddForm.productId = null;
      this.relationAddForm.relationType = undefined;
      this.relationAddForm.sort = 0;
      this.relationAddForm.title = null;
      this.relationEditType = 0;
    },
    // 检索商品
    onSearchRole(val) {
      this.fetching = false;
      this.list = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      console.log("-=-=-=-=-=-" + JSON.stringify(val));
      this.productForm.name = val
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/product-archives/manage/select-win",
                params: _this.productForm,
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  _this.list = res.data.records;
                  _this.fetching = false;
                }
              });
          }, 400);
        }
      });
    },
    // 打印选中商品
    onSelectRole(e) {
      console.log("--------" + JSON.stringify(e));
      this.relationAddForm.relatedProductId = e.productId;
    },
    // 上传前钩子
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequestProduct(fileData) {
      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file, bucket, this.fileProgressCallback, (url, fileName) => {
        // 上传完成
        if (url) {
          this.relationAddForm.photo = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },
    // 获取销售关系列表
    onGetRelationList() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/productRelation/list/" + this.productId,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.listData = res.data;
          }
        });
    },
    // 获取销售关系详情
    onGetRelationDetail(relationId) {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/productRelation/" + relationId,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.relationAddForm = res.data;
            this.showAddRelation(2);
          } else if (res.message) {
            this.$message.error(res.message);
          } else {
            this.$message.error("查询销售关系失败");
          }
          this.loading = false;
        });
    },
    // 删除销售关系
    removeRelation(relationId) {
      this.loading = true;
      this.$ajax({
        method: "DELETE",
        url: "/hxclass-management/productRelation/" + relationId,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("删除销售关系成功");
            this.onGetRelationList();
          } else if (res.message) {
            this.$message.error(res.message);
          } else {
            this.$message.error("删除销售关系失败");
          }
          this.loading = false;
        });
    },
    // 处理销售关系名称
    handleRelationType(type) {
      return type == 1 ? "赠品" : type == 2 ? "相关商品" : type == 3 ? "组合商品" : type == 4 ? "规格" : type == 5 ? "系列商品" : ""
    },
    // 重置销售关系数据
    resetData() {
      this.relationAddForm.price = null;
    },
    // 修改位置
    onSortChange(item) {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/productRelation",
            method: "put",
            params: {
              relationId: item.relationId,
              sort: item.sort
            }
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.onGetRelationList();
            }
          });
        // console.log('zoule')
      },600)
    },

  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getProductCategory() // 查询所属商品类别
    this.getSmsCordList() // 查询所属商品类别
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      this.formData.courseId = Number(this.$route.query.id)
      this.getFormDetail();
    });
  },
  // 生命周期-实例销毁离开后调用
  destroyed() { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./style.less";
</style>
